import React, { useEffect, createRef, useState } from "react";
import { AddressFinder } from "@ideal-postcodes/address-finder";

export default function AddressLookup() {
    const inputId = "line_1";

    const [address, setAddress] = useState({
        line_1: "",
        line_2: "",
        line_3: "",
        post_town: "",
        postcode: "",
        latitude: "",
        longitude: "",
    });

    React.useEffect(() => {
        AddressFinder.setup({
            inputField: document.getElementById(inputId),
            detectCountry: false,
            defaultCountry: "GBR",
            restrictCountries: ["GBR"],
            apiKey: "ak_kbv58axqhS72sCqyn9vhBXsdnaRvk",
            queryOptions: {
                country: ["Wales", "England"]
            },
            injectStyle: true,
            /* ... */
            onAddressRetrieved: ({ line_1, line_2, line_3, post_town, postcode, latitude, longitude }) =>
                setAddress({ line_1, line_2, line_3, post_town, postcode, latitude, longitude }),
            /* ... */
        });
    }, []);

    const fullAddress = (address) => {
        let addr = []
        if(address.line_1){
            addr.push(address.line_1);
        }
        if(address.line_2){
            addr.push(address.line_2);
        }
        if(address.line_3){
            addr.push(address.line_3);
        }
        if(address.post_town){
            addr.push(address.post_town);
        }
        if(address.postcode){
            addr.push(address.postcode);
        } 
        return addr.join(", ") 
    }


    return (
        <div className="App">
            <input
                type="text"
                id={inputId}
                //value={`${address.line_1}, ${address.line_2}`}
                value={address.line_1}
                className="form-control address-input"
                onChange={(e) => setAddress({ ...address, line_1: e.target.value })}
                placeholder={`Property postcode or area`}
                required
            />
             <input
                type="text" 
                id={"full_address"}
                value={`${fullAddress(address)}`} 
                className="form-control address-input disabled" 
                placeholder={`Address `}
                readOnly={true}
                disabled={true}
            />
            <div className="d-none">
            <label>Address Line One</label>
            <input
                type="text"
                value={address.line_1}
                onChange={(e) => setAddress({ ...address, line_1: e.target.value })}
                name="address1"
            />
            <label>Address Line Two</label>
            <input
                type="text"
                value={address.line_2}
                onChange={(e) => setAddress({ ...address, line_2: e.target.value })}
                name="address2"
            />
            <label>Address Line Three</label>
            <input
                type="text"
                value={address.line_3}
                onChange={(e) => setAddress({ ...address, line_3: e.target.value })}
                name="address3"
            />
            <label>Post Town</label>
            <input
                type="text"
                value={address.post_town}
                onChange={(e) => setAddress({ ...address, post_town: e.target.value })}
                name="address4"
            />
            <label>Postcode</label>
            <input
                type="text"
                value={address.postcode}
                onChange={(e) => setAddress({ ...address, postcode: e.target.value })}
                name="address5"
            />
             <label>latitude</label>
            <input
                type="text"
                value={address.latitude}
                onChange={(e) => setAddress({ ...address, latitude: e.target.value })}
                name="latitude"
            />
             <label>longitude</label>
            <input
                type="text"
                value={address.longitude}
                onChange={(e) => setAddress({ ...address, longitude: e.target.value })}
                name="longitude"
            />
            </div>
        </div>
    );
}
