import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from "./elements/input"
import SelectField from "./elements/select"
import TextAreaField from "./elements/textarea"
import CheckboxField from "./elements/checkbox"
import RadioField from "./elements/radio"
import ButtonField from "./elements/button"
import HtmlBox from "./elements/html"
import ReCaptchaBox from "./elements/recaptcha"
import { hubspot, postFormData } from "./api/Api"
import $ from "jquery"
import axios from "axios"
import * as qs from "query-string"
import _ from "lodash"
import AddressLookup from "../BannerWithForm/AddressLookup"
import { navigate as gatsbyNavigate } from "gatsby"
function MySimpleForm(props) {
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)

  const [formvalues, setFormvalues] = useState("")

  const [token, setToken] = useState("")

  const myRef = React.createRef()

  const recaptchaRef = React.createRef()

  const [email, setEmail] = useState("")

  const fields = [
    {
      element: "config",
      formname: "Instant Quote",
      form_type: "contact",
      error_text: "Highlighted fields are required",
      success_text:
        "Thank you for contacting us. A member of our team will contact you shortly.",
      email_temp_user: "instantquote_user",
      email_temp_admin: "instantquote_admin",
      email_subject_user: "Instant Quote",
      email_subject_admin: "Instant Quote",
      email_server_func: "Instant Quote",
      event_tracking: "Instant Quote",
      page_url: "/general-enquiry",
    },
    //   {
    //     grpmd: "12",
    //     name: "Instant Free quote",
    //     type:"submit",
    //     element: "button",
    //     value: "submit",
    //     class:"btn btn-pink w-100",
    //     labelClass: "content_b-18"
    //   },

    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef,
    },
  ]

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }
  const handleselectchange = event => {
    // remove initial empty spaces
    event.value = event.value.trimStart()
  }

  useEffect(() => {
    //   $(".price-input").on("keypress keyup blur",function (event) {
    //     $(this).val($(this).val().replace(/[^0-9\.]/g,''));
    //        if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
    //            event.preventDefault();
    //        }
    //  });

    const localEmail = localStorage.getItem("email")
    if (localEmail) {
      setEmail(localEmail)
    }

    $(document).ready(function () {
      $(".price-input").on("keypress keyup blur", function (event) {
        // skip for arrow keys
        if (
          (event.which != 46 || $(this).val().indexOf(".") != -1) &&
          (event.which < 48 || event.which > 57)
        ) {
          event.preventDefault()
        }
        var $this = $(this)
        var num = $this.val().replace(/,/gi, "").split("").reverse().join("")

        var num2 = RemoveRougeChar(
          num
            .replace(/(.{3})/g, "$1,")
            .split("")
            .reverse()
            .join("")
        )

        console.log(num2)

        // the following line has been simplified. Revision history contains original.
        $this.val(num2)
      })
    })

    function RemoveRougeChar(convertString) {
      if (convertString.substring(0, 1) == ",") {
        return convertString.substring(1, convertString.length)
      }
      return convertString
    }

    $(".form-field-group input").blur(function () {
      $(".form-field-group input").each(function () {
        if ($(this).is(":invalid")) {
          $(this).closest(".form-field-group").addClass("invalid--field")
        } else {
          $(this).closest(".form-field-group").removeClass("invalid--field")
        }
      })
    })
    // $(".select-option.form-field-group").change(function(){
    //   $(".select-option.form-field-group input[type=hidden]").each(function(){
    //     if ($(this).val() == "") {
    //       $(this).closest(".form-field-group").addClass("invalid--field");
    //     } else {
    //       $(this).closest(".form-field-group").removeClass("invalid--field");
    //     }
    //   })
    // });

    if (token !== "") {
      const processFromData = async () => {
        formvalues["g-recaptcha-response"] = token

        let formData = new FormData()
        


        // formvalues['extra'] = JSON.stringify({
        //   message: formvalues.message,
        //   name: formvalues.name
        // });
        formvalues["name"] = formvalues.name
        formvalues["email_subject_user"] = fields[0].email_subject_user
        formvalues["email_subject_admin"] = fields[0].email_subject_admin

        formData.append("data", JSON.stringify(formvalues)) 
        
        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          getQuote(formvalues) 
          // lets send mail
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
          document.getElementById("line_1").value = ""
          document.getElementById("full_address").value = ""
          document.getElementById("email").value = "" 
        }) 
        const url = typeof window !== "undefined" ? window.location.href : ""
        if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
          fields[0].formname = "Get mortgage help"
        }
        
        let myHeaders = new Headers();
        myHeaders.append("content-Type", "application/json");
        let accessToken=process.env.GATSBY_STRAPI_FORM_TOKEN
        myHeaders.append("Authorization", `Bearer ${accessToken}`);

        var raw = JSON.stringify({
          "email": formvalues.email,
          "first_name":"Quote",
          "last_name":"Requested",
          "saleprice": formvalues.price? parseFloat(formvalues.price.replace(/,/g, '')):0,
          "address1": formvalues.address1,
          "address2": formvalues.address2,
          "address3": formvalues.address3? formvalues.address3+ " "+ formvalues.address4:formvalues.address4,
          "postcode": formvalues.address5,
          "lifecyclestage":"lead",
        }) 

        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch("https://clozesure-strapi.q.starberry.com/hubapi/deal", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
              // tracking event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + fields[0].event_tracking,
          formId: "form-" + fields[0].event_tracking,
          formName: fields[0].formname,
          formLabel: formvalues.department + " - " + fields[0].formname,
        })

        setShowerror(false)
        //setThankyou(true);
        gatsbyNavigate("/thank-you-quote/", {
          state: {
            price: formvalues.price,
            address:
              formvalues.address1 +
              ", " +
              formvalues.address2 +
              ", " +
              formvalues.address3 +
              ", " +
              formvalues.address4 +
              ", " +
              formvalues.address5,
          },
        })
        myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      }
      processFromData()
    }
  }, [token])

  const fullAddress = (formvalues) => {
    let addr = []
    if(formvalues.address1){
        addr.push(formvalues.address1);
    }
    if(formvalues.address2){
        addr.push(formvalues.address2);
    }
    if(formvalues.address3){
        addr.push(formvalues.address3);
    }
    if(formvalues.address4){
        addr.push(formvalues.address4);
    }
    if(formvalues.address5){
        addr.push(formvalues.address5);
    } 
    return addr.join(", ") 
}

  const getQuote = (formvalues) =>{
    let myHeaders = new Headers();
    myHeaders.append("content-Type", "application/json");
    let apiKey = process.env.CLOZESURE_API_KEY
    myHeaders.append("x-api-key", 'a3907a2cd5712d753cdeaf6bc516cff741e65535ebf36ae44172029f2e1ffcf3');

    var bodyData = JSON.stringify({
      "deal": {
        "referralkey": "Ref1234",
        "email": formvalues.email,
        "property": {
          "address": {
            "line_1": fullAddress(formvalues.extra),
            "postcode": formvalues.extra?.address5,
            "latitude": formvalues.extra?.latitude,
            "longitude": formvalues.extra?.longitude,
            "uprn": "1234567890" 
          },
          "saleprice": parseInt(formvalues.price.replace(/,/g, '')) 
        }
      },
      "atlasid": "1234567890"
    });
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: bodyData,
      redirect: 'follow'
    };

    fetch("https://europe-west2-clozesureio.cloudfunctions.net/getQuote?referralkey=Ref1234", requestOptions)
    .then(response => response.text())
    .then(result => console.log('result', result))
    .catch(error => console.log('error', error));
  }

  const handleonVerify = token => {
    console.log("captcha verified")
    setToken(token)
  }

  const handleSubmit = event => {
    var iamvalue = $("#contactform .selectbox-dropdown input[name=iam]").val()
    if (iamvalue == "I am a ...") {
      $("#contactform .selectbox-dropdown").addClass("error-dropdown")
      event.preventDefault()
      event.stopPropagation()
    } else {
      $("#contactform .selectbox-dropdown").removeClass("error-dropdown")
      $(".form-field-group #validationiamname").val(iamvalue)
    }
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      $(".form-field-group input").each(function () {
        if ($(this).is(":invalid")) {
          $(this).closest(".form-field-group").addClass("invalid--field")
        } else {
          $(this).closest(".form-field-group").removeClass("invalid--field")
        }
      })
      // $(".select-option.form-field-group input[type=hidden]").each(function(){
      //   if ($(this).val() == "") {
      //     $(this).closest(".form-field-group").addClass("invalid--field");
      //   } else {
      //     $(this).closest(".form-field-group").removeClass("invalid--field");
      //   }
      // })
  
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      event.preventDefault()

      // set email to localstorage
      email && localStorage.setItem("email", email)

      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g-recaptcha-response"] = token
      json["extra"] = _.omit(json, ["formname", "name", "email", "telephone"])

      setFormvalues(json)
      //setToken("token")
      recaptchaRef.current.execute()

      setValidated(false) 
      // reset form
      const form = event.target
      form.reset()
    
      
    }
  }

  return (
    <div className="form stbform common_form_class" id="contactform">
      <div ref={myRef} />

      {showerror && (
        <div className="alert-error">
          <p>{fields[0].error_text}</p>
        </div>
      )}

      <div className="form-wraps input-wraps">
        {showthankyou && (
          <div className="alert-success">
            <p>{fields[0].success_text}</p>
          </div>
        )}
        <Form
          className="contact-form form-w-label"
          name={fields[0].formname}
          action="/thank-you/"
          method="post"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group className="banner-form-input">
            <Form.Control
              type="text"
              name="price"
              required
              placeholder="Agreed sale price"
              className="price-input"
              autocomplete="off"
            />
          </Form.Group>
          <Form.Group className="banner-form-input">
            {/* <Form.Control type="text" placeholder="Property postcode or area" className='address-input'/> */}
            <AddressLookup />
          </Form.Group>
          <Form.Group className="banner-form-input">
            <Form.Control
              required
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              className="email-input"
              autocomplete="off"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>
          <button type="submit" class="btn btn-pink w-100 btn">
            Instant Free Quote<i class="icon-right"></i>
          </button>

          <input type="hidden" name="form_name" value={fields[0].formname} />
          <input type="hidden" name="form_type" value={fields[0].form_type} />
          <input type="hidden" name="to_email_id" value={props.to_email_id} />
          <input type="hidden" name="bot-field" />

          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              )
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handleselectchange}
                  componentprops={props}
                />
              )
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              )
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  label={field.label}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              )
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  label={field.label}
                  name={field.name}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  checked={field.checked}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  lastchild={field.lastchild}
                />
              )
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              )
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              )
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              )
            }
          })}
        </Form>
      </div>
    </div>
  )
}

const ContactForm = props => (
  <MySimpleForm
    to_email_id={props.to_email_id}
    subject={props.subject}
    staff_name={props.staff_name}
    formtype={props.formtype}
    messagelabel={props.messagelabel}
    messageplaceholder={props.messageplaceholder}
  />
)

export default ContactForm
